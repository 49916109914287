import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './App.css';
import './Background.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route, Routes} from 'react-router-dom';
import {NotFound} from "./views/not-found/NotFound";
import {MainPage} from "./views/main-page/MainPage";

function App() {
    return (
        <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default App;
