import {partners} from "../../utils/partners";
import React from "react";
import './style.css';

export const Partners = () => {
    return (
        <>
            <section id="partners" className="background">
                <div className="text-container customers">
                    <p className="text title">Conoce a nuestros socios</p>
                </div>
                <div className="text-container">
                    <div className="card-grid-partner">
                        {partners.map((partner, index) => (
                            <a href={partner.url} target="_blank" rel="noreferrer">
                                <div key={partner.id} className="card-partner">
                                    <img className="card-partner-img" src={partner.image} alt={partner.name}/>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
