import React from "react";
import {Modal} from "react-bootstrap";
import {ICustomer} from "../../../utils/types";

export const ModalCustomers = (
    {
        showModalCustomer,
        handleCloseModalCustomer,
        customerSelected
    }: {
        showModalCustomer: boolean;
        handleCloseModalCustomer: () => void;
        customerSelected: ICustomer | undefined;
    }
) => {
    return (
        <Modal size="lg" show={showModalCustomer} onHide={handleCloseModalCustomer} centered>
            <Modal.Header closeButton>
                <Modal.Title>{customerSelected?.name || ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {customerSelected?.projects?.map((project, index) => (
                    <div>
                        <p>&nbsp;{'▪ ' + project}</p>
                        <br/>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
}