import React from "react";
import {
    IconEnvelope,
    IconFacebook,
    // IconInstagram,
    IconLinkedin,
    IconLocation,
    IconTelephone,
    IconTwitterX
} from "../../utils/icons";

export const Footer = () => {
    return (
        <footer id="contacto" className="footer">
            <div className="footer-container">
                <p className="contact">Contáctanos</p>
                <div className="footer-item">
                    <div className="d-flex align-items-center" style={{width: "auto"}}>
                        <img
                            alt="Teléfono"
                            src={IconTelephone}
                            height="16"
                            className="d-inline-block me-2"
                        />
                        <p className="mb-0">
                            <a className="footer-link" href="tel:+51 960 746 110" target="_blank"
                               rel="noopener noreferrer">(+51) 960 746 110</a> / {' '}
                            <a className="footer-link" href="tel:+51 987 516 519" target="_blank"
                               rel="noopener noreferrer">(+51) 987 516 519</a>
                        </p>
                    </div>

                </div>
                <div className="footer-item">
                    <a className="footer-link" href="mailto:ventas@cgll.com.pe" target="_blank"
                       rel="noopener noreferrer">
                        <div className="d-flex align-items-center">
                            <img
                                alt="Email"
                                src={IconEnvelope}
                                height="16"
                                className="d-inline-block me-2"
                            />
                            <p className="mb-0">ventas@cgll.com.pe</p>
                        </div>
                    </a>
                </div>
                <div className="footer-item">
                    <a className="footer-link" href="https://maps.app.goo.gl/hXXCu95GmYoiAnT38"
                       target="_blank"
                       rel="noopener noreferrer">
                        <div className="d-flex align-items-center">
                            <img
                                alt="Ubicación"
                                src={IconLocation}
                                height="16"
                                className="d-inline-block me-2"
                            />
                            <p className="mb-0">C. Victor Larco Herrera 3989, Los Olivos, Lima</p>
                        </div>
                    </a>
                </div>
                <p className='footer-item'>Síguenos en:</p>
                <div className='footer-item social-networks'>
                    <a href="https://www.facebook.com/cgllconsulting" target="_blank" rel="noopener noreferrer">
                        <img
                            alt="Facebook"
                            src={IconFacebook}
                            height="40px"
                            className="d-inline-block mx-3"
                        />
                    </a>
                    <a href="https://x.com/CGLLConsulting" target="_blank" rel="noopener noreferrer">
                        <img
                            alt="Twitter"
                            src={IconTwitterX}
                            height="40"
                            className="d-inline-block mx-3"
                        />
                    </a>
                    <a href="https://pe.linkedin.com/company/cgllconsulting" target="_blank"
                       rel="noopener noreferrer">
                        <img
                            alt="LinkedIn"
                            src={IconLinkedin}
                            height="40"
                            className="d-inline-block mx-3"
                        />
                    </a>
                    {
                        /* Instagram
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <img
                                    alt="Instagram"
                                    src={IconInstagram}
                                    height="40"
                                    className="d-inline-block mx-3"
                                />
                            </a>
                         */
                    }
                </div>
            </div>
            <div className='divider'></div>
            <p className='footer-text rights'>Todos los derechos reservados.</p>
        </footer>
    );
};