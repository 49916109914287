import React, {useState} from "react";
import OwlCarousel from "react-owl-carousel";
import {customers} from "../../utils/customers";
import {ICustomer} from "../../utils/types";
import {ModalCustomers} from "../modal/modal-customers/ModalCustomers";
import {renderToString} from "react-dom/server";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import './style.css';

const responsiveCustomers = {
    0: {
        items: 1 /* Smartphone */
    },
    768: {
        items: 3 /* Tablet */
    },
    1024: {
        items: 5 /* Laptop */
    },
    1440: {
        items: 5 /* Desktop */
    },
    2560: {
        items: 6 /* 4k */
    }
}

export const Customers = () => {
    const [showModalCustomer, setShowModalCustomer] = useState(false);
    const [customerSelected, setCustomerSelected] = useState<ICustomer>();

    const handleShowModalCustomer = () => setShowModalCustomer(true);

    const handleCloseModalCustomer = () => {
        setCustomerSelected(undefined);
        setShowModalCustomer(false);
    }
    const onClickItemCustomer = (id: string) => {
        const customer = customers.find(customer => customer.id === id)
        setCustomerSelected(customer);
        handleShowModalCustomer();
    }

    const navTextLeft = renderToString(<FaChevronLeft className="icon-right"/>);
    const navTextRight = renderToString(<FaChevronRight className="icon-left"/>);

    return (
        <>
            <section id="clientes" className="background">
                <div className='container customers'>
                    <div className="text-container customers">
                        <p className="text title">Ellos optaron por nosotros</p>
                    </div>
                    <div className="carousel-container">
                        <div className="text-container">
                            <OwlCarousel className='owl-theme' loop={false} margin={20} lazyLoad
                                         nav={true}
                                         dots={false}
                                         navText={[
                                             navTextLeft,
                                             navTextRight
                                         ]}
                                         responsive={responsiveCustomers}>
                                {customers.map(customer => (
                                    <div key={customer.id} className='item'>
                                        <div className='card card-customer'
                                             onClick={() => onClickItemCustomer(customer.id)}>
                                            <img className='card-customer-image'
                                                 src={customer.image}
                                                 alt={customer.name}/>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                    { /*
                    <div className="text-container">
                        <p className='text subtitle' style={{
                            color: 'var(--color-blue-500)',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: '1rem'
                        }}>Únete a nuestra red de clientes satisfechos</p>
                        <Button className='btn-custom' variant="primary">
                            Contáctanos</Button>
                    </div>
                    */}
                </div>
            </section>
            <ModalCustomers showModalCustomer={showModalCustomer} handleCloseModalCustomer={handleCloseModalCustomer}
                            customerSelected={customerSelected}/>
        </>
    );
};
