import React from "react";
import logoWhite from "../../images/logo_cgll_white.svg";
import './style.css';

export const NotFound = () => {
    return (
        <main
            className="flex flex-col items-center justify-center bg-rgb(5, 6, 45) px-6 py-24 sm:py-32 lg:px-8 h-screen text-white">
            <div className="text-center mb-12">
                <img src={logoWhite} alt="CG & LL Consulting"
                     className="h-24 mx-auto mb-4"/>
                <h1 className="text-3xl font-bold tracking-tight text-white sm:text-5xl">Página no encontrada</h1>
                <p className="mt-6 text-base leading-7">Lo sentimos, no pudimos encontrar la página que estás
                    buscando.</p>
            </div>
        </main>
    );
};
