import React from "react";
import whatsappIcon from "../../images/WhatsApp.svg";

const WhatsAppButton: React.FC = () => {
    return (
        <div style={{position: 'fixed', bottom: '1.5rem', right: '1.5rem', zIndex: 999}}>
            <a
                href="https://wa.link/bp7rhf"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    display: 'block',
                    width: '60px',
                    height: '60px',
                    transition: 'transform 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget as HTMLAnchorElement).style.transform = 'scale(1.1)'}
                onMouseLeave={(e) => (e.currentTarget as HTMLAnchorElement).style.transform = 'scale(1)'}
            >
                <img
                    src={whatsappIcon}
                    alt="WhatsApp Logo"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            </a>
        </div>
    );
};

export {WhatsAppButton};