import {IService} from "./types";

import datacenterImg from '../images/services/datacenter.png';
import outsourcingImg from '../images/services/outsorcing.png';
// import softwareImg from '../images/services/software.png';
import securityImg from '../images/services/security.png';
import innovationImg from '../images/services/innovation.png';
// import dataAnalyticsImg from '../images/services/data-analytics.png';
// import driveThruImg from '../images/services/drive-thru.png';
import subserviceImg from '../images/services/subcontratacion.png';
import {v4 as uuidv4} from 'uuid';

/*
const service1: IService = {
    id: '1',
    name: 'Infraestructura y Tecnología',
    description: 'Descubre nuestro moderno datacenter, tu plataforma confiable para almacenar, procesar y administrar datos de manera eficiente.',
    image: datacenterImg,
    subServices: [
        {
            name: 'Infraestructura',
            descriptions: [
                'Experimenta la potencia de la virtualización.',
                'Eleva tu negocio con nuestros servidores OnPremise.',
                'Realiza migraciones híbridas sin complicaciones.'
            ]
        },
        {
            name: 'Comunicaciones',
            descriptions: [
                'Conectividad punto final de alto rendimiento.',
                'Redes distribuidas para una cobertura total.',
                'Amplía tus horizontes con nuestra red WAN.'
            ]
        },
        {
            name: 'Storage',
            descriptions: [
                'Mantén tus datos seguros con nuestro servicio de respaldo.',
                'Replicación de datos para una continuidad sin igual.',
                'Soluciones de almacenamiento de última generación.'
            ]
        },
        {
            name: 'Performance',
            descriptions: [
                'Monitoreo detallado de aplicaciones en tiempo real.',
                'Revisiones exhaustivas con nuestro HealthCheck.',
                'Mantén tus servidores bajo control con nuestro monitoreo especializado.'
            ]
        },
        {
            name: 'Power',
            descriptions: [
                'Equipos inteligentes para un rendimiento óptimo.',
                'UPS confiables para proteger tus operaciones.',
                'Soluciones de energía confiables, incluidos transformadores de aislamiento y grupos electrógenos.'
            ]
        }
    ]
};

const service2: IService = {
    id: '2',
    name: 'Soporte Empresarial',
    description: 'Maximiza el potencial de tu empresa con nuestra consultoría y servicios de staffing y outsourcing, diseñados para optimizar tus operaciones y éxito.',
    image: outsourcingImg,
    subServices: [
        {
            name: 'Cloud',
            descriptions: [
                'Dominamos las plataformas GCP, AWS y Azure para llevar tu negocio al siguiente nivel.'
            ]
        },
        {
            name: 'Help Desk',
            descriptions: [
                'Soporte microinformático y de servicios siempre a tu disposición.',
                'Help Desk funcional para resolver cualquier problema rápidamente.'
            ]
        },
        {
            name: 'Centro de Servicios Gestionados',
            descriptions: [
                'Confía en nosotros para la gestión integral de tus servicios.'
            ]
        },
        {
            name: 'Gestión de Aplicaciones',
            descriptions: [
                'Optimiza el rendimiento y la eficiencia de tus aplicaciones.'
            ]
        },
        {
            name: 'Outsourcing',
            descriptions: [
                'Aprovecha al máximo tus recursos con nuestro servicio de Outsourcing, delegando las tareas operativas y enfocándote en el núcleo de tu negocio.'
            ]
        },
        {
            name: 'Staffing',
            descriptions: [
                'Fortalece tus iniciativas digitales con expertos en TI altamente calificados, mientras nosotros nos encargamos del reclutamiento, selección, formación y gestión del personal, permitiéndote concentrarte en tus operaciones principales.'
            ]
        }
    ]
};

const service3: IService = {
    id: '3',
    name: 'Software y Desarrollo',
    description: 'Impulsa tu negocio con nuestras soluciones de software y desarrollo a medida, adaptadas a tus necesidades específicas para un rendimiento óptimo.',
    image: softwareImg,
    subServices: [
        {
            name: 'Licenciamiento',
            descriptions: [
                'Asesoramiento experto para tus necesidades de licenciamiento.'
            ]
        },
        {
            name: 'Fábrica de Software',
            descriptions: [
                'Desarrollamos soluciones a medida para tu negocio.'
            ]
        },
        {
            name: 'CI / CD',
            descriptions: [
                'Implementamos la integración continua y la entrega continua para un desarrollo eficiente.'
            ]
        },
        {
            name: 'DevOps',
            descriptions: [
                'Alinea tus equipos de desarrollo y operaciones para una entrega más rápida y confiable.'
            ]
        },
        {
            name: 'Multiarquitectura y Multiplataforma',
            descriptions: [
                'Soluciones adaptables a cualquier entorno.'
            ]
        },
        {
            name: 'Migraciones Legacy',
            descriptions: [
                'Moderniza tu infraestructura con nuestras soluciones de migración.'
            ]
        }
    ]
};

const service4: IService = {
    id: '4',
    name: 'Análisis de Datos',
    description: 'Maximiza el valor de tus datos con nuestras herramientas y análisis avanzados, proporcionando información clave para la toma de decisiones estratégicas.',
    image: dataAnalyticsImg,
    subServices: [
        {
            name: 'Big Data',
            descriptions: [
                'Descubre el poder oculto en tus datos masivos.',
                'Transforma la información en oportunidades tangibles para tu negocio.'
            ]
        },
        {
            name: 'Data Scientist',
            descriptions: [
                'Nuestros expertos desentrañan patrones y tendencias para impulsar tu éxito.',
                'Obtén insights valiosos para la toma de decisiones estratégicas.'
            ]
        },
        {
            name: 'Business Intelligence',
            descriptions: [
                'Convierte tus datos en conocimiento accionable para un crecimiento significativo.',
                'Visualiza tus datos de manera clara y concisa para una comprensión inmediata.'
            ]
        },
        {
            name: 'Data Lake',
            descriptions: [
                'Almacena, gestiona y analiza tus datos con facilidad y seguridad.',
                'Accede a una fuente única de verdad para una toma de decisiones informada y precisa.'
            ]
        },
        {
            name: 'Machine Learning y Deep Learning',
            descriptions: [
                'Implementa soluciones avanzadas de aprendizaje automático para anticipar las necesidades del mercado.',
                'Utiliza algoritmos sofisticados para maximizar la eficiencia y la rentabilidad.'
            ]
        },
        {
            name: 'Data Platform a medida',
            descriptions: [
                'Desarrollamos plataformas personalizadas para tus necesidades específicas.',
                'Maximiza el valor de tus datos con soluciones adaptadas a tu entorno empresarial.'
            ]
        },
        {
            name: 'Data Mesh',
            descriptions: [
                'Adopta una arquitectura moderna y escalable para gestionar tus datos de manera efectiva.',
                'Desbloquea todo el potencial de tus datos con una infraestructura ágil y flexible.'
            ]
        },
        {
            name: 'Data Ware House',
            descriptions: [
                'Centraliza tus datos para una gestión más eficiente y un análisis más profundo.',
                'Construye una base sólida para tu estrategia de datos con nuestras soluciones de almacenamiento.'
            ]
        }
    ]
};

const service5: IService = {
    id: '5',
    name: 'Seguridad Empresarial',
    description: 'Protege tu negocio con nuestras sólidas medidas de seguridad empresarial, garantizando la confidencialidad, integridad y disponibilidad de tus activos digitales.',
    image: securityImg,
    subServices: [
        {
            name: 'Servicios de Seguridad',
            descriptions: [
                'Protege tu negocio contra amenazas cibernéticas con nuestras soluciones integrales de seguridad.',
                'Mantén tus datos seguros en todo momento con nuestro equipo experto en seguridad.'
            ]
        },
        {
            name: 'Soluciones de Seguridad',
            descriptions: [
                'Implementa medidas proactivas para salvaguardar la integridad de tu empresa.',
                'Minimiza los riesgos y protege tus activos con nuestras soluciones avanzadas.'
            ]
        },
        {
            name: 'Consultoría de Seguridad',
            descriptions: [
                'Obtén asesoramiento personalizado de nuestros expertos en seguridad.',
                'Identifica y mitiga vulnerabilidades potenciales en tu infraestructura con nuestra evaluación exhaustiva.'
            ]
        }
    ]
};

const service6: IService = {
    id: '6',
    name: 'Innovación y Transformación',
    description: 'Atrévete a innovar y transformar tu empresa con nosotros, explorando nuevas ideas y tecnologías para impulsar el crecimiento y la competitividad.',
    image: innovationImg,
    subServices: [
        {
            name: 'IOT',
            descriptions: [
                'Conecta tus dispositivos para una operación más inteligente y eficiente.',
                'Aprovecha el poder de la Internet de las cosas para transformar tu negocio.'
            ]
        },
        {
            name: 'IA for Business',
            descriptions: [
                'Integra la inteligencia artificial en tu estrategia empresarial para obtener ventajas competitivas.',
                'Automatiza procesos y toma decisiones más informadas con nuestras soluciones de IA.'
            ]
        },
        {
            name: 'ChatBOT',
            descriptions: [
                'Mejora la experiencia del cliente con asistentes virtuales inteligentes.',
                'Resuelve consultas y brinda soporte de manera rápida y eficiente con nuestra tecnología de ChatBOT.'
            ]
        }
    ]
};

const service7: IService = {
    id: '7',
    name: 'Drive-Thru: Ventas y Soporte',
    description: 'Haz avanzar tu negocio con nuestro servicio de Drive-Thru especializado en la venta de equipos y soporte técnico. Equipos de última generación y asistencia experta para mantener tu operación en marcha sin problemas.',
    image: driveThruImg,
    subServices: [
        {
            name: 'Equipos Avanzados',
            descriptions: [
                'Amplia selección de equipos de alta tecnología para agilizar tu Drive-Thru.',
                'Intercomunicadores modernos y sistemas de pago sin contacto para impulsar tu negocio.'
            ]
        },
        {
            name: 'Soporte Técnico Premium',
            descriptions: [
                'Expertos disponibles para resolver rápidamente cualquier problema técnico.',
                'Desde instalación hasta mantenimiento, garantizamos un funcionamiento sin problemas.'
            ]
        }
    ]
};
*/

const service1: IService = {
    id: uuidv4(),
    name: 'Soluciones',
    description: 'Desarrollamos soluciones personalizadas para potenciar tu negocio, desde software a medida hasta integración de sistemas, maximizando eficiencia.',
    image: datacenterImg,
    subServices: [
        {
            id: uuidv4(),
            name: 'Datacenter',
            descriptions: [
                'Procesamiento',
                'Almacenamiento de Datos',
                'Redes LAN/SAN y Comunicaciones',
                'Telefonía IP y Comunicaciones Unificadas',
                'Cableado Estructural y Fibra Óptica',
                'Respaldo y Recuperación de Datos',
                'Energía y Distribución Eléctrica'
            ]
        },
        {
            id: uuidv4(),
            name: 'Cloud',
            descriptions: [
                'Plataformas Cloud (Azure, AWS, GCP, Huawei)',
                'Suites de Productividad (Microsoft 365, Google Workspace)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Ciberseguridad',
            descriptions: [
                'Protección de Dispositivos (AV, DLP, EDR, etc.)',
                'Monitoreo y Gestión de Eventos (SIEM)',
                'Seguridad Web y de Contenido',
                'Defensa Perimetral (NGFW, IPS, AntiDDOS, VPN)',
                'Seguridad de Correo Electrónico',
                'Protección de Bases de Datos'
            ]
        },
        {
            id: uuidv4(),
            name: 'Arrendamiento Operativo',
            descriptions: [
                'Equipos de Oficina (Desktops, Laptops, Impresoras, etc.)',
                'Equipos para Datacenter (Switches, Servidores, Firewalls, Almacenamiento, etc.)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Servicios para Restaurantes (Drive Thru)',
            descriptions: [
                'Automatización de Ventas y Autoservicio'
            ]
        }
    ]
}

const service2: IService = {
    id: uuidv4(),
    name: 'Servicios TI',
    description: 'Soporte integral para tu empresa, desde gestión de redes hasta seguridad cibernética, asegurando un entorno digital confiable.',
    image: innovationImg,
    subServices: [
        {
            id: uuidv4(),
            name: 'Infraestructura y Virtualización',
            descriptions: [
                'Virtualización y Orquestación',
                'Nubes Públicas y Privadas',
                'Almacenamiento y Administración de Datos',
                'Monitoreo de Aplicaciones y Rendimiento',
                'Optimización de Redes LAN/WAN',
                'Gestión de Datacenter y Networking',
                'Respaldo y Recuperación de Datos',
                'Alta Disponibilidad y Continuidad de Negocio',
                'Migración y Actualización de Plataformas',
                'Auditorías y Revisiones de Salud (Health Check)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Análisis de Datos',
            descriptions: [
                'Big Data y Analítica Avanzada',
                'Ciencia de Datos y Analistas',
                'Inteligencia de Negocios y Gestión de Datos',
                'Analítica Avanzada e Inteligencia Artificial',
                'Data Lakes y Almacenamiento de Datos',
                'Aprendizaje Automático y Profundo (Machine Learning/Deep Learning)',
                'Plataformas de Datos Personalizadas',
                'Almacenes de Datos (Data Warehouses)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Colaboración y Productividad',
            descriptions: [
                'Comunicaciones Unificadas y Colaboración',
                'Portales Corporativos e Intranets/Extranets',
                'Gestión Documental y Workflow',
                'Aprobaciones y Flujos de Trabajo',
                'Correo Electrónico y Comunicaciones',
                'Telefonía IP y VoIP',
                'Redes Sociales Empresariales',
                'Migración y Actualización de Plataformas de Colaboración'
            ]
        },
        {
            id: uuidv4(),
            name: 'Innovación y Transformación Digital',
            descriptions: [
                'Internet de las Cosas (IoT)',
                'Inteligencia Artificial para Negocios',
                'Chatbots y Asistentes Virtuales',
                'Automatización Robótica de Procesos (RPA)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Seguridad Empresarial',
            descriptions: [
                'Centro de Operaciones de Ciberseguridad (Cyber SOC)',
                'Pruebas de Penetración Ética (Ethical Hacking)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Desarrollo de Software',
            descriptions: [
                'Gestión de Licencias y Software',
                'Desarrollo de Software a Medida',
                'Integración Continua / Despliegue Continuo (CI/CD)',
                'Prácticas de Desarrollo y Operaciones (DevOps)',
                'Arquitecturas y Plataformas Múltiples',
                'Desarrollo Multiplataforma',
                'Migraciones de Sistemas Legados'
            ]
        }
    ]
}

const service3: IService = {
    id: uuidv4(),
    name: 'Consultoría',
    description: 'Asesoramiento estratégico en tecnología para optimizar procesos, implementar soluciones innovadoras y potenciar tu competitividad.',
    image: outsourcingImg,
    subServices: [
        {
            id: uuidv4(),
            name: 'Infraestructura On-Premise y Cloud',
            descriptions: [
                'Consultoría de Soluciones en Cloud',
                'Implementación de Infraestructura On-Premise y en la Cloud'
            ]
        },
        {
            id: uuidv4(),
            name: 'Seguridad Informática y Ciberseguridad',
            descriptions: [
                'Evaluación de Vulnerabilidades y Seguridad',
                'Pruebas de Penetración Ética (Ethical Hacking)',
                'Análisis y Mitigación de Malware',
                'Investigación Forense Digital',
                'Evaluación de Arquitectura y Cumplimiento Normativo (NIST-ISO 27000)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Redes y Comunicaciones',
            descriptions: [
                'Análisis de Infraestructura de Redes',
                'Diseño y Optimización de Redes LAN, WAN y WLAN'
            ]
        },
        {
            id: uuidv4(),
            name: 'Gestión de Base de Datos y Software',
            descriptions: [
                'Evaluación y Mantenimiento de Bases de Datos',
                'Optimización y Ajuste de Rendimiento',
                'Migración y Gestión de Datos On-Premise y en la Nube'
            ]
        }
    ]
}

const service4: IService = {
    id: uuidv4(),
    name: 'Servicios Administrados',
    description: 'Gestionamos tus sistemas y redes para garantizar seguridad y rendimiento óptimo, liberándote para enfocarte en tu negocio.',
    image: securityImg,
    subServices: [
        {
            id: uuidv4(),
            name: 'Seguridad Gestionada',
            descriptions: [
                'Gestión de Firewalls',
                'Protección de EndPoints',
                'Control de Acceso a la Red (NAC)',
                'Gestión de Eventos e Información de Seguridad (SIEM)',
                'Automatización y Orquestación de Respuesta de Seguridad (SOAR)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Gestión de Cloud Público',
            descriptions: [
                'Infraestructura como Servicio (IaaS)',
                'Plataforma como Servicio (PaaS)',
                'Software como Servicio (SaaS)'
            ]
        },
        {
            id: uuidv4(),
            name: 'Gestión de Redes LAN y WLAN',
            descriptions: [
                'Gestión de Switches Core',
                'Gestión de Switches L2 y L3',
                'Gestión de Controladores Wireless',
                'Gestión de Access Points',
                'Portal Cautivo y Gestión de Acceso a la Red'
            ]
        }
    ]
}

const service5: IService = {
    id: uuidv4(),
    name: 'Outsourcing',
    description: 'Externaliza procesos tecnológicos y optimiza operaciones, desde soporte técnico hasta gestión de aplicaciones, reduciendo costos y mejorando eficiencia.',
    image: subserviceImg,
    subServices: [
        {
            id: uuidv4(),
            name: 'Mesa de Ayuda',
            descriptions: [
                'Nivel 1: Recepción y Registro de Incidentes',
                'Nivel 2: Resolución de Incidentes Avanzados'
            ]
        },
        {
            id: uuidv4(),
            name: 'Especialistas en TI',
            descriptions: [
                'Consultores Especializados en Seguridad',
                'Especialistas en Infraestructura Tecnológica',
                'Analistas Funcionales de Sistemas',
                'Soporte Técnico Especializado',
                'Desarrolladores de Software',
                'Analistas de Control de Calidad (QA)',
                'Coordinación de Soporte y Gestión'
            ]
        }
    ]
}

export const services = [
    service1,
    service2,
    service3,
    service4,
    service5
].sort((a, b) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
});
