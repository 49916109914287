import React, {useState} from "react";
import logo from "../../images/cgll_gris.svg";
import {FaEnvelope, FaHome, FaTools, FaUserFriends, FaUserTie} from "react-icons/fa";
import './style.css';

export const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scrollToElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
            setIsMenuOpen(false);
        }
    };

    return <>
        <nav className="bg-gray-800 fixed top-0 w-full z-10">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 flex items-center justify-between h-20">
                <div className="flex items-center">
                    <img className="nav-brand" src={logo} alt="CG & LL Consulting"/>
                </div>
                <div className="hidden sm:flex sm:items-center sm:ml-6">
                    <div className="flex space-x-4">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#inicio"
                           className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-m font-medium flex items-center">
                            <FaHome className="h-5 w-5 mr-2"/>
                            Inicio
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#nosotros"
                           className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-m font-medium flex items-center">
                            <FaUserTie className="h-5 w-5 mr-2"/>
                            Nosotros
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#servicios"
                           className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-m font-medium flex items-center">
                            <FaTools className="h-5 w-5 mr-2"/>
                            Servicios
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#clientes"
                           className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-m font-medium flex items-center">
                            <FaUserFriends className="h-5 w-5 mr-2"/>
                            Clientes
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#contacto"
                           className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-m font-medium flex items-center">
                            <FaEnvelope className="h-5 w-5 mr-2"/>
                            Contacto
                        </a>
                        {
                            // Intranet
                            /*
                            <a href="https://www.office.com/" target="_blank" rel="noreferrer"
                               className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium flex items-center">
                                <FaBriefcase className="h-4 w-4 mr-2"/>
                                Intranet
                            </a>
                            */
                        }

                    </div>
                </div>
                <div className="sm:hidden">
                    <button type="button"
                            className="navbar-btn-menu relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            aria-controls="mobile-menu" aria-expanded={isMenuOpen}
                            onClick={toggleMenu}>
                        <span className="absolute -inset-0.5"></span>
                        <span className="sr-only">Open main menu</span>
                        <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="var(--color-blue-500)" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                        </svg>
                        <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
                <div className="space-y-1 px-2 pb-3 pt-2">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => scrollToElement('inicio')}
                       className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Inicio</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => scrollToElement('nosotros')}
                       className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Nosotros</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => scrollToElement('servicios')}
                       className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Servicios</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => scrollToElement('clientes')}
                       className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Clientes</a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" onClick={() => scrollToElement('contacto')}
                       className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Contacto</a>
                    {
                        // Intranet
                        /*
                        <a href="https://www.office.com/" target="_blank" rel="noreferrer"
                       className="navbar-custom-item text-gray-700 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Intranet</a>
                        */
                    }
                </div>
            </div>
        </nav>
    </>;
}
