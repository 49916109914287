import {NavBar} from "../../components/navbar/NavBar";
import {Home} from "../../components/home/Home";
import {Services} from "../../components/services/Services";
import {AboutUs} from "../../components/about-us/AboutUs";
import {Customers} from "../../components/customers/Customers";
import {WhatsAppButton} from "../../components/whatsapp-button/WhatsappButton";
import {Footer} from "../../components/footer/Footer";
import {Partners} from "../../components/partners/Partners";
import React from "react";

const MainPage = () => {
    return (
        <>
            <NavBar/>
            <Home/>
            <Services/>
            <AboutUs/>
            <Customers/>
            <Partners/>
            <WhatsAppButton/>
            <Footer/>
        </>
    );
}

export {MainPage};