import {IPartner} from "./types";

import a10Img from '../images/partners/A10.png';
import arubaImg from '../images/partners/Aruba.png';
import acImg from '../images/partners/Audiocodes.png';
import awsImg from '../images/partners/AWS.png';
import ciscoImg from '../images/partners/Cisco.png';
import commscopeImg from '../images/partners/Commscope.png';
import dellImg from '../images/partners/DELL.png';
import fortinetImg from '../images/partners/Fortinet.png';
import googleImg from '../images/partners/Google.png';
import hpImg from '../images/partners/HP.png';
import huaweiImg from '../images/partners/Huawei.png';
import ironscalesImg from '../images/partners/Ironscales.png';
import kasperskyImg from '../images/partners/Kaspersky.png';
import lenovoImg from '../images/partners/Lenovo.png';
import microsoftImg from '../images/partners/Microsoft.png';
import nutanixImg from '../images/partners/Nutanix.png';
import optralImg from '../images/partners/Optral.png';
import paloaltoImg from '../images/partners/Paloalto.png';
import polyImg from '../images/partners/Poly.png';
import riverbedImg from '../images/partners/Riverbed.png';
import veeamImg from '../images/partners/Veeam.png';
import vmWareImg from '../images/partners/VMWare.png';
import yealinkImg from '../images/partners/Yealink.png';
import hmeImg from '../images/partners/HME.png';

const partner1: IPartner = {
    id: '1',
    name: 'A10 Networks',
    image: a10Img,
    url: 'https://www.a10networks.com/'
}

const partner2: IPartner = {
    id: '2',
    name: 'Aruba Networks',
    image: arubaImg,
    url: 'https://www.arubanetworks.com/'
}

const partner3: IPartner = {
    id: '3',
    name: 'AudioCodes',
    image: acImg,
    url: 'https://www.audiocodes.com/'
}

const partner4: IPartner = {
    id: '4',
    name: 'Amazon Web Services',
    image: awsImg,
    url: 'https://aws.amazon.com/'
}

const partner5: IPartner = {
    id: '5',
    name: 'Cisco',
    image: ciscoImg,
    url: 'https://www.cisco.com/'
}

const partner6: IPartner = {
    id: '6',
    name: 'Dell',
    image: dellImg,
    url: 'https://www.dell.com/'
}

const partner7: IPartner = {
    id: '7',
    name: 'Fortinet',
    image: fortinetImg,
    url: 'https://www.fortinet.com/'
}

const partner8: IPartner = {
    id: '8',
    name: 'HP',
    image: hpImg,
    url: 'https://www.hp.com/'
}

const partner9: IPartner = {
    id: '9',
    name: 'Huawei',
    image: huaweiImg,
    url: 'https://www.huawei.com/'
}

const partner10: IPartner = {
    id: '10',
    name: 'Lenovo',
    image: lenovoImg,
    url: 'https://www.lenovo.com/'
}

const partner11: IPartner = {
    id: '11',
    name: 'Nutanix',
    image: nutanixImg,
    url: 'https://www.nutanix.com/'
}

const partner12: IPartner = {
    id: '12',
    name: 'Veeam',
    image: veeamImg,
    url: 'https://www.veeam.com/'
}

const partner13: IPartner = {
    id: '13',
    name: 'VMWare',
    image: vmWareImg,
    url: 'https://www.vmware.com/'
}

const partner14: IPartner = {
    id: '14',
    name: 'Yealink',
    image: yealinkImg,
    url: 'https://www.yealink.com/'
}

const partner15: IPartner = {
    id: '15',
    name: 'Commscope',
    image: commscopeImg,
    url: 'https://www.commscope.com/'
}

const partner16: IPartner = {
    id: '16',
    name: 'Google',
    image: googleImg,
    url: 'https://cloud.google.com/'
}

const partner17: IPartner = {
    id: '17',
    name: 'Ironscales',
    image: ironscalesImg,
    url: 'https://ironscales.com/'
}

const partner18: IPartner = {
    id: '18',
    name: 'Kaspersky',
    image: kasperskyImg,
    url: 'https://www.kaspersky.com/'
}

const partner19: IPartner = {
    id: '19',
    name: 'Microsoft',
    image: microsoftImg,
    url: 'https://www.microsoft.com/'
}

const partner20: IPartner = {
    id: '20',
    name: 'Optral',
    image: optralImg,
    url: 'https://www.optral.com/'
}

const partner21: IPartner = {
    id: '21',
    name: 'Palo Alto',
    image: paloaltoImg,
    url: 'https://www.paloaltonetworks.com/'
}

const partner22: IPartner = {
    id: '22',
    name: 'Poly',
    image: polyImg,
    url: 'https://www.poly.com/'
}

const partner23: IPartner = {
    id: '23',
    name: 'Riverbed',
    image: riverbedImg,
    url: 'https://www.riverbed.com/'
}

const partner24: IPartner = {
    id: '24',
    name: 'HME',
    image: hmeImg,
    url: 'https://www.hme.com/'
}

export const partners = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
    partner10,
    partner11,
    partner12,
    partner13,
    partner14,
    partner15,
    partner16,
    partner17,
    partner18,
    partner19,
    partner20,
    partner21,
    partner22,
    partner23,
    partner24
].sort((a, b) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
});
