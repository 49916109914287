import React from "react";
import './style.css';
import aboutUsImg from "../../images/about-us-3.jpg";

export const AboutUs = () => {
    return (
        <section id="nosotros" className="background">
            <div className='container'>
                <div className='background-container about-us'>
                    <div className="text-content">
                        <p className="text title title-about-us">Sobre nosotros</p>
                        <p className="text subtitle subtitle-about-us">Impulsa tu negocio con nuestras
                            soluciones de
                            infraestructura. En CG&LL Consulting, te ofrecemos asesoramiento experto y
                            servicios de consultoría en seguridad, migración, implantación y operaciones de
                            TI.</p>
                        <p className="text subtitle subtitle-about-us" style={{fontWeight: 'bold'}}>¡Optimiza tu
                            productividad hoy
                            mismo!</p>
                    </div>
                    <img src={aboutUsImg} alt="Descripción de la imagen" className="card-custom-image"/>
                </div>
            </div>
        </section>
    );
};
