import React, {useEffect, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import {services} from "../../utils/services";
import {IService} from "../../utils/types";
import './style.css';
import {FaChevronUp, FaChevronDown} from "react-icons/fa";
import {renderToString} from "react-dom/server";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

const responsiveServices = {
    0: {
        items: 1 /* Smartphone */
    },
    426: {
        items: 2 /* Tablet */
    },
    769: {
        items: 3 /* Laptop */
    },
    1025: {
        items: 4 /* Desktop */
    },
    2560: {
        items: 5 /* 4k */
    }
}

interface IExpandedService {
    serviceId: string | null;
}

interface IExpandedSubService {
    serviceId: string | null;
    subServiceId: string | null;
}

// @ts-ignore
export const Services = () => {
    const [expandedService, setExpandedService] = useState<IExpandedService | null>(null);
    const [expandedSubService, setExpandedSubService] = useState<IExpandedSubService | null>(null);
    const [, forceUpdate] = useState({});

    const navTextLeft = renderToString(<FaChevronLeft className="icon-right"/>);
    const navTextRight = renderToString(<FaChevronRight className="icon-left"/>);

    const toggleService = (serviceId: string) => {
        if (expandedService && expandedService.serviceId === serviceId) {
            setExpandedService(null);
        } else {
            setExpandedService({serviceId: serviceId});
        }
        // Collapse any expanded sub-service
        setExpandedSubService(null);
    };

    const toggleSubService = (serviceId: string, subServiceId: string) => {
        if (expandedSubService && expandedSubService.serviceId === serviceId && expandedSubService.subServiceId === subServiceId) {
            setExpandedSubService({serviceId: serviceId, subServiceId: null});
        } else {
            setExpandedSubService({serviceId: serviceId, subServiceId: subServiceId});
        }
    };

    useEffect(() => {
        // Force re-render when either expandedService or expandedSubService changes
        forceUpdate({});
    }, [expandedService, expandedSubService]);

    return (
        <section id="servicios" className="background">
            <div className='container services'>
                <div className="text-container services">
                    <p className="text title">Nuestros servicios a tu disposición</p>
                </div>
                <div className="carousel-container">
                    <div className="text-container">
                        <OwlCarousel className='owl-theme' loop={false} margin={20} lazyLoad nav={true}
                                     dots={false}
                                     navText={[
                                         navTextLeft,
                                         navTextRight
                                     ]}
                                     responsive={responsiveServices}>
                            {services.map((service: IService) => (
                                <div key={service.id} className='item item-service'>
                                    <div className='card card-service'>
                                        <img src={service.image} alt={service.name}/>
                                        <p className='service-title'>{service.name}</p>
                                        <p className='service-description'>{service.description}</p>
                                        <button className="button-expanded" title="Ver más"
                                                onClick={() => toggleService(service.id, )}>
                                            {expandedService && expandedService.serviceId === service.id ?
                                                <FaChevronUp className="icon-expanded"/> :
                                                <FaChevronDown className="icon-expanded"/>}
                                        </button>
                                        {expandedService && expandedService.serviceId === service.id && (
                                            <div className='sub-services'>
                                                {service.subServices.map((subService, index) => (
                                                    <div key={subService.id} className='sub-service'>
                                                        <div className='sub-service-header'>
                                                            <p className="sub-service-title">▹ {subService.name}</p>
                                                            <button className="button-expanded" title="Ver más"
                                                                    onClick={() => toggleSubService(service.id, subService.id)}>
                                                                {expandedSubService && expandedSubService.serviceId === service.id && expandedSubService.subServiceId === subService.id ?
                                                                    <FaChevronUp className="icon-expanded"/> :
                                                                    <FaChevronDown className="icon-expanded"/>}
                                                            </button>
                                                        </div>
                                                        <div className="sub-service-content">
                                                            {expandedSubService && expandedSubService.serviceId === service.id && expandedSubService.subServiceId === subService.id && (
                                                                <ul>
                                                                    {subService.descriptions.map((description, index) => (
                                                                        <li className="sub-service-description"
                                                                            key={index}>○ {description}</li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};
