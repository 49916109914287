import {ICustomer} from "./types";

import bbvaImg from '../images/customers/BBVA.png';
import cajaCuscoImg from '../images/customers/Caja-Cusco.png';
import gmoImg from '../images/customers/GMO.png';
import alpayanaImg from '../images/customers/Grupo-Alpayana.png';
import rokysImg from '../images/customers/Rokys.png';
import skyImg from '../images/customers/SKY-Airline.png';
import minCulturaImg from '../images/customers/Ministerio-de-Cultura.png';
import minInteriorImg from '../images/customers/Ministerio-del-Interior.png';
import presidenciaImg from '../images/customers/Presidencia-de-la-Republica-del-Peru.png';
import redrilsaImg from '../images/customers/Redrilsa.png';
import entelImg from '../images/customers/Entel.png';
import mambrinoImg from '../images/customers/Mambrino.png';
import ingemmetImg from '../images/customers/INGEMMET.png';
import konectaImg from '../images/customers/Konecta.png';
import topitopImg from '../images/customers/Topitop.png';
import minPublicoImg from '../images/customers/Ministerio-Publico.png';
import subwayImg from '../images/customers/Subway.png';
import delosiImg from '../images/customers/Delosi.png';
import upsjbImg from '../images/customers/Universidad-Privada-San-Juan-Bautista.png';
// import hmeImg from '../images/customers/HME.png';

const customer1: ICustomer = {
    id: 'bbva',
    name: 'BBVA',
    image: bbvaImg,
    projects: [
        'Servicio de Virtualización de Escritorios VDI con Microsoft en Alta Disponibilidad (HA).',
        'Implementación del System Center Configuration Manager 2019 con Microsoft en Alta Disponibilidad (HA) para más de 7000 Equipos.'
    ]
}

const customer2: ICustomer = {
    id: 'caja-cusco',
    name: 'Caja Cusco',
    image: cajaCuscoImg,
    projects: [
        'Servicio de Implementación de System Center Operations Manager 2016 con Microsoft, abarcando más de 200 Servidores (Microsoft y Linux).'
    ]
}

const customer3: ICustomer = {
    id: 'gmo',
    name: 'GMO',
    image: gmoImg,
    projects: [
        'Arrendamiento de Equipos de Cómputo con HPE Inc.',
        'Implementación de Proyectos de Redes Inalámbricas con Cisco.'
    ]
}

const customer4: ICustomer = {
    id: 'alpayana',
    name: 'Alpayana',
    image: alpayanaImg,
    projects: [
        'Servicio de Soporte y Mantenimiento de Plataforma de Virtualización con Vmware.',
        'Servicio de Implementación de un Nuevo Bosque ADDS con Microsoft.',
        'Servicio de Migración de Base de Datos a SQL Server 2019 para una Gestión Eficiente de Datos.'
    ]
}

const customer5: ICustomer = {
    id: 'rokys',
    name: `Roky's`,
    image: rokysImg,
    projects: [
        'Migración de Servicio de Correo a Office 365 con Microsoft para una Eficiencia Inigualable.',
        'Automatización de Procesos de Negocio para un Funcionamiento Optimizado.'
    ]
}

const customer6: ICustomer = {
    id: 'sky',
    name: 'SKY Airline',
    image: skyImg,
    projects: [
        'Implementación de Seguridad Perimetral con Fortinet.',
        'Despliegue de Redes Inalámbricas con Tecnología Fortinet.',
        'Arrendamiento de Equipos de Comunicación para una Conectividad sin Precedentes.'
    ]
}

const customer7: ICustomer = {
    id: 'delosi',
    name: 'Delosi S.A.',
    image: delosiImg,
    projects: [
        'Gestión de Base de Datos con Oracle.',
        'Soluciones de Mesa de Ayuda Nivel 1 y 2 para más de 500 Tiendas.',
        'Migración de Correo a Office 365 para más de 2000 Cuentas.',
        'Automatización de Procesos de Negocio para un Desempeño Impecable.'
    ]
}

const customer8: ICustomer = {
    id: 'despacho-presidencial',
    name: 'Despacho Presidencial',
    image: presidenciaImg,
    projects: [
        'Soporte y Mantenimiento de la Plataforma de Correo (Microsoft).',
        'Servicio Especializado en la Migración de Bases de Datos Oracle en Alta Disponibilidad (HA).'
    ]
}

const customer9: ICustomer = {
    id: 'ministerio-del-interior',
    name: 'Ministerio del Interior',
    image: minInteriorImg,
    projects: [
        'Implementación de Soluciones de Respaldo con Veritas.',
        'Servicio de Migración de Dominio (Microsoft) para más de 5000 Usuarios.'
    ]
}

const customer10: ICustomer = {
    id: 'redrilsa',
    name: 'Redrilsa',
    image: redrilsaImg,
    projects: [
        'Servicio de Cableado Estructurado Cat-6ª con Panduit, Certificados en más de 400 Puntos.',
        'Servicio de Segmentación de LAN con Tecnología Cisco.',
        'Implementación de Seguridad Perimetral con Fortinet.',
        'Implementación de Office 365 con Microsoft para un Trabajo más Eficiente.'
    ]
}

const customer11: ICustomer = {
    id: 'entel',
    name: 'Entel',
    image: entelImg,
    projects: [
        'Servicio de Outsourcing de Mesa de Ayuda y Soporte Especializado en Clouds, atendiendo Office 365 (Microsoft) y GSuite (Google) para 182 Empresas.'
    ]
}

const customer12: ICustomer = {
    id: 'mambrino',
    name: 'Mambrino',
    image: mambrinoImg,
    projects: [
        'Implementación de Proyecto de Redes Inalámbricas y Portal Cautivo con Ruckus para las Tiendas Lucio Caffe.'
    ]
}

const customer13: ICustomer = {
    id: 'ministerio-de-cultura',
    name: 'Ministerio de Cultura',
    image: minCulturaImg,
    projects: [
        'Servicio de Implementación de Soluciones de Respaldo con Veritas.',
        'Servicio de Soporte y Mantenimiento de Plataforma (Microsoft) para una Operación sin Interrupciones.'
    ]
}

const customer14: ICustomer = {
    id: 'ingemmet',
    name: 'INGEMMET',
    image: ingemmetImg,
    projects: [
        'Servicio de Migración de Dominio Active Directory 2019 con Microsoft.',
        'Servicio de Migración de Correo Electrónico Exchange Server 2019 con Microsoft en Alta Disponibilidad (HA).'
    ]
}

const customer15: ICustomer = {
    id: 'konecta',
    name: 'Konecta',
    image: konectaImg,
    projects: [
        'Servicio de Actualización de Plataforma de Virtualización con VMware para 12 Hosts.'
    ]
}

const customer16: ICustomer = {
    id: 'topitop',
    name: 'Topitop',
    image: topitopImg,
    projects: [
        'Servicio de Implementación de un Nuevo Bosque ADDS (Microsoft) en Lima y Provincias.',
        'Desarrollo de Aplicaciones Web/Móviles a Medida.',
        'Servicio de Despliegue de Renovación Tecnológica en Lima y Provincias.'
    ]
}

const customer17: ICustomer = {
    id: 'ministerio-publico',
    name: 'Ministerio Público',
    image: minPublicoImg,
    projects: [
        'Migración de Servicio de Archivos desde Novell a Microsoft File Server.',
        'Servicio de Implementación de un Nuevo Bosque ADDS (Microsoft) para una Gestión Eficiente de Directorios.'
    ]
}

const customer18: ICustomer = {
    id: 'subway',
    name: 'Subway',
    image: subwayImg,
    projects: [
        'Servicio Integral de Soporte Técnico Nivel 1 y 2 para Garantizar la mejor Operatividad.'
    ]
}

const customer19: ICustomer = {
    id: 'upsjb',
    name: 'Universidad Privada San Juan Bautista',
    image: upsjbImg,
    projects: [
        'Soluciones que simplifican la gestión de usuarios tanto en Active Directory como en Office 365, asegurando una administración fluida y eficaz de los recursos digitales clave.'
    ]
}

/**
 const customer20: ICustomer = {
 id: 'hme',
 name: 'HME',
 image: hmeImg,
 projects: [
 'Venta de Equipos y Soporte para Drive-Thru en Delosi S.A.',
 'Venta de Equipos y Soporte para Drive-Thru en Arcos Dorados Holdings Inc.'
 ]
 }
 **/

export const customers = [
    customer1,
    customer2,
    customer3,
    customer4,
    customer5,
    customer6,
    customer7,
    customer8,
    customer9,
    customer10,
    customer11,
    customer12,
    customer13,
    customer14,
    customer15,
    customer16,
    customer17,
    customer18,
    customer19
    // customer20
].sort((a, b) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
});