import React from "react";
import {TypewriterEffectSmooth} from "../ui/typewriter-effect";
import './style.css';

export function TypewriterEffectSmoothDemo() {
    const words = [
        {
            text: "Hacemos",
            className: "text-white dark:text-white"
        },
        {
            text: "de",
            className: "text-white dark:text-white"
        },
        {
            text: "lo",
            className: "text-white dark:text-white"
        },
        {
            text: "imposible,",
            className: "text-white dark:text-white"
        },
        {
            text: "algo",
            className: "text-white dark:text-white"
        },
        {
            text: "simple.",
            className: "text-white dark:text-white"
        }
    ];
    return (
        <div className="flex flex-col items-center justify-center h-full w-100">
            <h1 className="text-gray-300 dark:text-gray-300 text-s sm:text-base md:text-xl lg:text:3xl xl:text-5xl ">
                CG & LL Consulting
            </h1>
            <TypewriterEffectSmooth words={words}/>
        </div>
    );
}

export const Home = () => {
    return (
        <section id="inicio" className='background'>
            {
                TypewriterEffectSmoothDemo()
            }
        </section>
    );
};